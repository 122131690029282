/**
 * calories.js
 */
(function ($) {
    $(document).ready(function () {
        if ($('#calories-counter').length !== 0) {
            $('.buttons button').removeAttr('disabled');
        }

        $('#calories-counter .alcohol').on('click', '.plus, .minus', function () {
            handleCaloriesClick.call(this, $(this).hasClass('plus') ? 1 : -0.5);
        });

        function handleCaloriesClick(increment) {
            let amount = $(this).parent().parent().find('.amount');
            let value = parseFloat(amount.text());
            let calories = $(this).parent().parent().data('calories');
            if (!value) {
                value = 0;
            }
            value += increment;
            if (value < 0) {
                value = 0;
            }
            amount.text(value.toFixed(1))
            $(this).parent().parent().data('totalCalories', value * calories);
            calculatetotalCalories();
        }

        function calculatetotalCalories() {
            let calories = 0;
            $('#calories-counter .alcohol, #bac-counter .alcohol').each(function () {
                if ($(this).data('totalCalories') === undefined) {
                    $(this).data('totalCalories', 0);
                }
                calories += parseFloat($(this).data('totalCalories'));
            });
            $('#total-calories').text(calories.toFixed(0) + ' kcal');
        }
    });

})(jQuery);
